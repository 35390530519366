import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";

const auth = getAuth();

export const ProtectedRoute = ({element}) => {
	const hostname = window.location.hostname;
	let isLocalHost = false;
	// if (hostname === "localhost" || hostname === "127.0.0.1") {
	// 	isLocalHost = true;
	// }
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const navigate = useNavigate();
	
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setIsAuthenticated(!!user);
		});
		return () => unsubscribe();
	}, []);
	
	useEffect(() => {
		const isLoggedInLocalStorage = localStorage.getItem("quant_user");
		console.log('isLoggedInLocalStorage', isLoggedInLocalStorage);
		if (isAuthenticated || isLoggedInLocalStorage) {
			return;
		} else {
			navigate("/");
		}
	}, [isAuthenticated, navigate]);
	
	return isAuthenticated ? element : null;

};

// Redirect after login
export const RedirectOnLogin = () => {
	const navigate = useNavigate();
	let isLocalHost = true;
	
	useEffect(() => {
		const isLoggedInLocalStorage = localStorage.getItem("quant_user");
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!isLocalHost && (user || isLoggedInLocalStorage)) {
				window.location.href = "https://app.applyjobs.ai";
				navigate("/");
			}
		});
		
		return () => unsubscribe();
	}, []);
	
	return null;
};

// Redirect after logout
export const RedirectOnLogout = () => {
	const navigate = useNavigate();
	let isLocalHost = true;
	
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!isLocalHost && (!user)) {
				localStorage.removeItem("isLoggedIn");
				window.location.href = "https://applyjobs.ai";
				navigate("/");
			}
		});
		
		return () => unsubscribe();
	}, []);
	
	return null;

};
