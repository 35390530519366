var hostPath = window.location.host
hostPath = hostPath.replace(/:.*/, "");
const isLocalHost = ['127.0.0.1', 'localhost'].includes(hostPath)

export function getAxiosBaseUrl() {
	// return 'https://mailchamp.ai:' + getPythonPort();
	
	if (isLocalHost) {
		return 'http://localhost:' + getPythonPort(); //Keep it localhost for cookie consistency
	} else {
		return 'https://applyjobs.ai:' + getPythonPort();
	}
}

export function getBaseUrlNodeServer() {
	return 'https://mailchamp.ai:8080/'
	// if (isLocalHost) {
	// 	return 'http://127.0.0.1:8080/'
	// } else {
	// 	return 'https://mailchamp.ai:8080/'
	// }
}

export function getPythonPort() {
	return '5001'
}