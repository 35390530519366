// @ts-ignore
import { ReviewTexts } from "Components/particles/DataLists";
import { useCallback } from "react";
import { Text } from "Components/atoms/Text";
import { Fade } from "react-awesome-reveal";
import { Card } from "Components/molecules/Card";
import ProfileImg1 from "../../assets/profile1.jpeg";
import ProfileImg2 from "../../assets/profile2.jpeg";
import ProfileImg3 from "../../assets/profile3.jpeg";
import ProfileImg4 from "../../assets/profile4.jpeg";
import { Button } from "Components/atoms/Button";

const Review = () => {
  const renderProfileImg = useCallback((element: number) => {
    switch (element) {
      case 0:
        return ProfileImg2;
      case 1:
        return ProfileImg1;
      case 2:
        return ProfileImg3;
      case 3:
        return ProfileImg4;
      case 4:
        return ProfileImg3;
      case 5:
        return ProfileImg4;
      default:
        return "";
    }
  }, []);
  return (
    <>
      <div
        className="lg:px-24 lg:h-[1000px]  md:h-[1800px] h-[120rem] md:px-20 px-6"
        style={{
          background: `  radial-gradient(circle at center top , black 65%, transparent 100%),
          radial-gradient(circle at center left, blue 40%, transparent 50%),
          radial-gradient(circle at right top, purple 40%, transparent 50%)`,
        }}    
      >
        <div className="text-center p-[2px]">
          <Text
            as="h2"
            className="md:text-4xl mt-[40px] text-2xl font-medium capitalize text-white"
          >
            <Fade>{ReviewTexts.firstText}</Fade>
          </Text>
        </div>

        <div className="w-full mt-[40px] p-[10px]">
          <div className="flex  gap-5 flex-wrap p-[3px] justify-center mt-[10px] h-[350px]">
            {ReviewTexts.cards.map((card, index) => (
              <Card
                key={index}
                cardClass="w-[300px] h-[250px] bg-[#dcdcdc] flex flex-col cursor-pointer transition duration-300 hover:shadow-xl  rounded-xl cardPseudo hover:bg-[#fff] hover:border-blue-500 hover:border-opacity-50"
                imageAlt={card.person}
                imageSrc={renderProfileImg(index)}
                imageWrapperClass="w-20 h-20 rounded-full mx-auto  mt-[10px] overflow-hidden"
              >
                <Text as="h4" className="text-base rounded font-medium text-color3 flex justify-center mt-1 p-1">
            {card.person}
          </Text> 
          <Text as="p" className="text-sm font-light text-center text-color3">
            {card.text}
          </Text>  
           <div className="flex justify-center mt-3 mb-2">
           <Button className="outline-none border-none p-[8px] bg-color2 text-white font-light rounded-lg ">{"see more"}</Button>
           </div>
          
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
