// @ts-ignore
import {Image} from "../atoms/Image.tsx"
// @ts-ignore
import bgImage from "../../assets/HeroVector.png"
// @ts-ignore
import dashboardImage from "../../assets/dashboard3.png"

// @ts-ignore
import {Text} from "../atoms/Text.tsx"
// @ts-ignore
import {HeroTexts} from "../particles/DataLists.tsx"
// @ts-ignore
import {Button} from "../atoms/Button.tsx"
// @ts-ignore
import {Fade, Slide} from "react-awesome-reveal"
// @ts-ignore
import * as React from "react"
import {useEffect, useRef, useState} from "react"
// @ts-ignore
import {RegisterForm} from "../molecules/RegisterForm.tsx";
import {ModalPopup} from "../molecules/ModalPopup.tsx";
import {SigninForm} from "../molecules/SigninForm.tsx";
import StarRating from "./StarRating.js"
import FuriosaPoster from "./FuriosaPoster.js";

const HeroSection = ({registeredUsers, jobsAvailable, jobsApplied}) => {
    const [activeModal, setActiveModal] = useState<string>('');
    return (
        <>
            <div style={{
                height: '50%',
                background: `
                            radial-gradient(circle at center top, black 75%, transparent 100%),
                            radial-gradient(circle at right bottom, purple 40%, transparent 40%),
                            radial-gradient(circle at left bottom, blue 40%, transparent 40%)
                            `
            }}>
                <div className="w-full lg:h-screen md:h-[550px] h-[1030px] relative overflow-x-hidden flex justify-end"
                >
                    <main
                        className=" gap-4 w-full lg:h-full h-auto grid md:grid-cols-2 absolute top-0 left-0 lg:px-24 md:px-8 px-5 pt-24 md:pt-32 lg:pt-0">
                        <div className="flex flex-col justify-center md:gap-6 gap-3 md:order-1 order-2"
                             style={{order: '1'}}>
                            <Text as="p"
                                  className="text-white uppercase tracking-widest lg:text-base text-sm font-normal text-center md:text-start">
                                <Fade>{HeroTexts.firstText}</Fade>
                            </Text>
                            <Text as="h1"
                                  className="text-white lg:text-7xl md:text-5xl text-3xl font-medium bg-gradient-to-r from-orange-400 via-red-500 to-pink-400 text-transparent bg-clip-text font-sans m-0 md:text-start text-center">
                                <Fade>{HeroTexts.secondText}</Fade>
                            </Text>
                            <Text as="p"
                                  style={{textAlign: 'justify'}}
                                  className=" flex flex-col text-center  text-white md:text-base text-sm  font-light">
                                <Fade>{HeroTexts.thirdText}</Fade>
                            </Text>
                            <div
                                className="flex w-full mb-1 mt-1 justify-center items-center gap-0 md:justify-start md:gap-6 lg:gap-12"
                                style={{}}>
                                <Button type="button"
                                        className="outline-none border-none bg-color2 text-white font-light rounded-lg px-5 py-3 lg:px-9 sm:mx-auto md:ml-0"
                                        onClick={() => setActiveModal('Signup')}
                                        style={{
                                            paddingTop: '1rem',
                                            paddingBottom: '1rem',
                                            fontSize: '1rem',
                                            fontWeight: "bold",
                                            letterSpacing: '2px'
                                        }}>
                                    {HeroTexts.firstButton}
                                </Button>
                                {activeModal != '' && <ModalPopup onClose={() => setActiveModal('')}
								                                  children={activeModal == 'login' ?
                                                                      <SigninForm onClose={() => setActiveModal('')}/> :
                                                                      <RegisterForm
                                                                          onClose={() => setActiveModal('')}/>}
								                                  heading={activeModal == 'login' ? "SignIn" : "Signup"}
								                                  description={activeModal == 'login' ? "Enter verification ID sent from MAilChamp.Ai" : "Enter your email to register."}/>}
                            </div>
                            <StarRating rating={4.5}/>
                            <Text as="p"
                                  className="text-white capitalize-first tracking-widest lg:text-base text-sm font-normal text-center md:text-start">
                                <Fade>{registeredUsers ? `4.5 Star Rated /  ${registeredUsers.toLocaleString('en-US')} Clients` : "Loading..."}</Fade>
                            </Text>
                            <Text as="p"
                                  className="text-white capitalize-first tracking-widest lg:text-base text-sm font-normal text-center md:text-start">
                                <Fade>{jobsApplied ? `${jobsApplied.toLocaleString('en-US')} Jobs Applied` : "Loading..."}</Fade>
                            </Text>
                            <Text as="p"
                                  className="text-white capitalize-first tracking-widest lg:text-base text-sm font-normal text-center md:text-start">
                                <Fade>{jobsAvailable ? `${jobsAvailable.toLocaleString('en-US')} New Jobs` : "Loading..."}</Fade>
                            </Text>
                        </div>
                        <div
                            className="flex flex-col items-center justify-center md:order-2 order-1 sm:ml-12 ml-12 ">
                            <Slide direction="right">
                                {/*<FuriosaPoster/>*/}
                                <Image image={dashboardImage} alt="Hero Image"
                                       className=" rounded-lg lg:h-[85%] hover:shadow-lg md:w-full w-[90%] h-[50vh]"/>
                            </Slide>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default HeroSection