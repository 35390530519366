import { Fade } from "react-awesome-reveal";
//@ts-ignore
import video from "../../assets/video.mp4";
import { Text } from "Components/atoms/Text";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

const VideoTabs = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY;
      console.log(yPos);
      if (yPos >130) {
        console.log(yPos, "y");
        controls.start({ x: 0, opacity: 1 });
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);
  return (
    <>
      <div
        className="lg:px-24 md:px-20 px-6"
        style={{
          background: `radial-gradient(circle at center top , black 45%, transparent 100%),
          radial-gradient(circle at bottom left, blue 40%, transparent 100%),
          radial-gradient(circle at bottom right , purple 70%, transparent 30%)`,
        }}
      >
        <div className="text-center mb-[10%] py-[30px]">
          <h2 className="text-center  md:text-4xl text-2xl font-medium capitalize text-white">
            <Fade>Our Feautres</Fade>
          </h2>
        </div>
        <div className="w-full flex lg:flex-row flex-col-reverse">
          <div className="lg:w-[40%] w-[100%] flex lg:justify-end  justify-center">
            <motion.div
              animate={controls}
              initial={{ x: "-100vw", opacity: 0 }}
              transition={{ type: "spring", stiffness: 100 }}
              className=" w-[400px]"
            >
              <h2 className="text-center mt-[40px] md:text-4xl text-2xl font-medium capitalize text-white">
                <Fade>Lorem, ipsum dolor.</Fade>
              </h2>
              <Text
                as="p"
                className="text-center p-[10px] text-l  font-light  text-white"
              >
                Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit.
                Lorem ipsum dolor sit.
              </Text>
            </motion.div>
          </div>
          <div className="lg:w-[60%] w-[100%] flex justify-center">
            {/* <div className=' w-full h-[380px] flex  border-[8px] rounded-lg border-black'> */}
            <video
              src={video}
              autoPlay
              loop
              muted
              style={{ height: "300px", width: "80%" }}
              controls
            />
            {/* </div> */}
          </div>
        </div>
        <div className="w-full flex mt-[10%] mb-[10%]  lg:flex-row flex-col  ">
          <div className="lg:w-[60%] w-[100%] flex justify-center">
            <video
              src={video}
              autoPlay
              loop
              muted
              style={{ height: "300px", width: "80%" }}
              controls
            />
          </div>
          <div className="lg:w-[40%]  w-[100%] ">
            <div className="  lg:w-[400px]">
              <h2 className="text-center mt-[40px] md:text-4xl text-2xl font-medium capitalize text-white">
                <Fade>Lorem, ipsum dolor.</Fade>
              </h2>
              <Text
                as="p"
                className="text-center p-[10px] text-l  font-light  text-white"
              >
                Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit.
                Lorem ipsum dolor sit.
              </Text>
            </div>
          </div>
        </div>
        <div className="w-full flex lg:flex-row flex-col-reverse">
          <div className="lg:w-[40%] w-[100%] flex lg:justify-end justify-center">
            <motion.div
              animate={controls}
              initial={{ x: "-100vw", opacity: 0 }}
              transition={{ type: "spring", stiffness: 100 }}
              className=" w-[400px]"
            >
              <h2 className="text-center mt-[40px] md:text-4xl text-2xl font-medium capitalize text-white">
                <Fade>Lorem, ipsum dolor.</Fade>
              </h2>
              <Text
                as="p"
                className="  text-center p-[10px] text-l  font-light  text-white"
              >
                Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit.
                Lorem ipsum dolor sit.
              </Text>
            </motion.div>
          </div>
          <div className="lg:w-[60%] pb-[3rem] w-[100%] flex justify-center">
            {/* <div className=' w-full h-[380px] flex  border-[8px] rounded-lg border-black'> */}
            <video
              src={video}
              autoPlay
              loop
              muted
              style={{ height: "300px", width: "80%" }}
              controls
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoTabs;
