import { useState } from "react";
import { ModalPopup } from "./ModalPopup";
import { SigninForm } from "./SigninForm";
import { RegisterForm } from "./RegisterForm";

export const LoginSignupModal = ({ onClose, isLocalRun }) => {
  const [activeTab, setActiveTab] = useState("login");

  return (
    <ModalPopup onClose={onClose}>
      <div className="flex justify-evenly mt-8 mb-4 border-gray-300 ">
        <button
          className={`px-2 py-2 transition-colors duration-300 rounded-lg w-1/2 mx-2   ${
            activeTab === "login"
              ? "  bg-color1 rounded-lg text-white shadow-xl"
              : ""
          }`}
          onClick={() => setActiveTab("login")}
        >
          Sign in
        </button>
        <button
          className={`px-2 py-2 transition-colors duration-300 rounded-lg w-1/2 mx-2 ${
            activeTab === "signup"
              ? " bg-color1 rounded-lg text-white shadow-xl"
              : ""
          }`}
          onClick={() => setActiveTab("signup")}
        >
          New account
        </button>
      </div>

      <div className="p-4">
        {activeTab === "login" ? (
          <SigninForm onClose={onClose} isLocalRun={isLocalRun}/>
        ) : (
          <RegisterForm onClose={onClose} isLocalRun={isLocalRun}/>
        )}
      </div>
    </ModalPopup>
  );
};
// import { useState } from "react";
// import { ModalPopup } from "./ModalPopup";
// import { SigninForm } from "./SigninForm";
// import { RegisterForm } from "./RegisterForm";

// export const LoginSignupModal = ({ onClose }) => {
//   const [activeTab, setActiveTab] = useState("login");

//   return (
//     <ModalPopup onClose={onClose}>
//       <div className="flex justify-evenly mt-8 mb-4 border-gray-300 ">
//         <button
//           className={`px-2 py-2 transition-colors duration-300 rounded-lg w-1/2 mx-2   ${
//             activeTab === "login"
//               ? "  bg-color1 rounded-lg text-white shadow-xl"
//               : ""
//           }`}
//           onClick={() => setActiveTab("login")}
//         >
//           Sign in
//         </button>
//         <button
//           className={`px-2 py-2 transition-colors duration-300 rounded-lg w-1/2 mx-2 ${
//             activeTab === "signup"
//               ? " bg-color1 rounded-lg text-white shadow-xl"
//               : ""
//           }`}
//           onClick={() => setActiveTab("signup")}
//         >
//           New account
//         </button>
//       </div>

//       <div className="p-4">
//         {activeTab === "login" ? (
//           <SigninForm onClose={onClose} />
//         ) : (
//           <RegisterForm onClose={onClose} />
//         )}
//       </div>
//     </ModalPopup>
//   );
// };
