import React, { useState } from "react";
import { ModalPopup } from "./ModalPopup";

const PaymentModal = ({ packageInfo, onClose }) => {
  const [email, setEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("paypal");

  const handlePayment = () => {
    if (paymentMethod === "paypal") {
      console.log("Processing PayPal payment...");
    } else if (paymentMethod === "credit_card") {
      console.log("Processing Credit Card payment...");
    }
    onClose();
  };

  return (
    <ModalPopup onClose={onClose}>
      <div className="flex flex-col">
        <h2 className="text-4xl my-8 font-bold text-center">
          Choose Payment Method
        </h2>
        <div className="flex justify-center mb-4">
          <label className="inline-flex items-center mx-4">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-color1"
              value="paypal"
              checked={paymentMethod === "paypal"}
              onChange={() => setPaymentMethod("paypal")}
            />
            <span className="ml-2">PayPal Account</span>
          </label>
          <label className="inline-flex items-center mx-4">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-color1"
              value="credit_card"
              checked={paymentMethod === "credit_card"}
              onChange={() => setPaymentMethod("credit_card")}
            />
            <span className="ml-2">Credit Card</span>
          </label>
        </div>
        {paymentMethod === "paypal" && (
          <p className="text-center text-gray-500 my-4">
            You will be redirected to PayPal for payment processing.
          </p>
        )}
        {paymentMethod === "credit_card" && (
          <div>{/* credit card library  */}</div>
        )}
        <button
          onClick={handlePayment}
          className="border w-full justify-center outline-none bg-color1 py-2 px-6 text-white text-base rounded-lg hover:transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black font-bold hover:shadow-lg mt-4"
        >
          Pay Now
        </button>
      </div>
    </ModalPopup>
  );
};

export default PaymentModal;
