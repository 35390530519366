import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import Home from "./Components/pages/Home";
import NewFooter from "./Components/organs/Footer.tsx";
import NavBar from "./Components/organs/NavBar";
import {ProtectedRoute, RedirectOnLogin, RedirectOnLogout,} from "./Components/molecules/AuthUtils.js";
import {Route, Routes} from "react-router-dom";
import PaidBlog from "./Components/pages/PaidBlog";
import PaidMemberChats from "./Components/pages/PaidMemberChats";

import {ToastContainer} from "react-toastify";
import {getAuth} from "firebase/auth";
import {app} from "./firebase";
import axios from "axios";
import {getAxiosBaseUrl} from "./config";

const auth = getAuth(app);
let isLocalRun = false
const hostname = window.location.hostname;
if (hostname === "localhost" || hostname === "127.0.0.1") {
	isLocalRun = true
}

function App() {
	const [isloggedin, setisloggedin] = useState(false);
	const registeredUsers = 297;
	const jobsAvailable = 49851;
	const jobsApplied = 891602;
	
	function getSessionData() {
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.get('/get_session', {
			withCredentials: true
		}).then(response => {
			if (response.data.unique_session_id) {
				if (isLocalRun) {
					window.location.href = 'http://localhost:3001/'
				} else {
					window.location.href = 'https://app.applyjobs.ai';
				}
			} else {
				console.log('response ', response.data);
			}
		}).catch(error => {
			console.error('Error getting session data:', error);
		});
	}
	
	useEffect(() => {
		getSessionData();
	}, []);
	
	
	function handleClosePrivacyModal() {
		this.setState({privacyModalOpen: false});
		// localStorage.setItem('privacyAccepted', true);
		// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))
	}
	
	// if (localStorage.getItem('privacyAccepted') == 'false') {
	// 	window.location.href = 'https://applyjobs.ai/privacypolicy';
	// }
	// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'));
	// console.log('privacyModalOpen', this.state.privacyModalOpen);
	return (
		<div className="app-container">
			<Routes>
				<Route
					path="/paid-blog"
					element={<ProtectedRoute element={<PaidBlog/>}/>}
				/>
				<Route
					path="/paid-member-chats"
					element={<ProtectedRoute element={<PaidMemberChats/>}/>}
				/>
			</Routes>
			<NavBar isLocalRun={isLocalRun}/>
			{/*<div style={{height: "80px", width: "100vw", marginBottom: "-200px"}}>*/}
			{/*</div>*/}
			<Home registeredUsers={registeredUsers} jobsAvailable={jobsAvailable} jobsApplied={jobsApplied}/>
			<NewFooter/>
			<RedirectOnLogin/>
			<RedirectOnLogout/>
			<ToastContainer/>
			{/*DO NO DELETE*/}
			{/*New Responsibitlies being transitioned here to this repo*/}
			{/*{this.state.privacyModalOpen && <PrivacyPopup onClose={this.handleClosePrivacyModal}/>}*/}
			{/*// var signedin = sessionStorage.getItem("signedin");*/}
			{/*// const privacyAccepted = localStorage.getItem('privacyAccepted');*/}
			{/*// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))*/}
			{/*// if (!privacyAccepted) {*/}
			{/*// 	this.setState({privacyModalOpen: false})*/}
			{/*// }*/}
		</div>
	
	);
}

export default App;
