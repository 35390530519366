//@ts-ignore
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Text } from "Components/atoms/Text";
import { Fade } from "react-awesome-reveal";

export default function Expandable() {
  return (
    <div
      className="lg:px-24 md:px-20 px-6 pb-[8rem] h-[850px]  flex  items-start flex-col "
      style={{
        background: ` radial-gradient(circle at center top, black 75%, transparent 100%),
    radial-gradient(circle at right bottom, purple 40%, transparent 40%),
    radial-gradient(circle at left bottom, blue 40%, transparent 40%)`,
      }}
    >
      <Text
        className="md:text-4xl text-2xl my-[4rem] font-medium capitalize mx-[auto] text-white "
        as="h2"
      >
        <Fade>Lorem ipsum dolor sit.</Fade>
      </Text>
      <div className="flex w-[80%] flex-col mx-auto">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ height: "100px" }}
          >
            Lorem, ipsum dolor.
          </AccordionSummary>
          <AccordionDetails sx={{ height: "100px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ height: "100px" }}
          >
            Lorem, ipsum dolor.
          </AccordionSummary>
          <AccordionDetails sx={{ height: "100px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ height: "100px" }}
          >
            Lorem, ipsum dolor.
          </AccordionSummary>
          <AccordionDetails sx={{ height: "100px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
