import { useState } from "react";
import { ModalPopup } from "./ModalPopup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";

const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const auth = getAuth();

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!");
        setEmailSent(true);
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  };

  return (
    <ModalPopup onClose={onClose}>
      <div className=" flex-col flex">
        <h2 className="text-4xl my-8 font-bold text-center ">
          Forgot your Password?
        </h2>
        <p className="text-center text-l px-4 my-4 text-gray-500">
          Enter your email address and we'll send you a link to set your
          password.
        </p>
        <label
          htmlFor="email"
          className="text-lg text-start mx-1 font-bold text-gray-600"
        >
          Email
        </label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border rounded-lg px-4 py-2 outline-none focus:ring-2 focus:ring-blue-500 mb-4 mt-2"
        />
        <button
          onClick={handleResetPassword}
          className="border w-full justify-center outline-none bg-color1 py-2 px-6 text-white  text-base rounded-lg  hover:transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black font-bold hover:shadow-lg"
        >
          Send
        </button>

        {emailSent && (
          <p className="text-center text-l px-4 my-4 text-gray-500">
            A password reset link has been sent to your
            <span className=" underline block text-color1 font-bold">
              {email}
            </span>
          </p>
        )}

        <div className="flex justify-center my-8">
          <h1>know your password?</h1>
          <button
            className="text-color1 font-bold hover:text-color2 mx-2"
            onClick={onClose}
          >
            Sign in
          </button>
        </div>
      </div>
    </ModalPopup>
  );
};

export default ForgotPassword;
