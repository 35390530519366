import * as React from "react";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";

type ModalPopupProps = {
  heading?: string;
  description?: string;
  children?: React.ReactNode;
  onClose: () => void;
};

export const ModalPopup = ({
  children,
  heading,
  description,
  onClose,
}: ModalPopupProps) => {
  const [showModal, setShowModal] = useState<boolean>(true);

  function modalClickHandler() {
    setShowModal(false);
    onClose();
  }

  const closeCombinedModal = () => {
    setShowModal(false);
    onClose();
  };

  const handleModalContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {showModal && (
        <>
          <div
            className="py-6 no-scrollbar animate-fade-in fixed inset-0 z-50 flex w-full items-center justify-center overflow-x-hidden outline-none backdrop-blur-sm focus:outline-none overflow-y-scroll "
            onClick={closeCombinedModal}
            style={{
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none", // For Internet Explorer
            }}
          >
            <style>
              {`
                .no-scrollbar::-webkit-scrollbar {
                  display: none; // Hide scrollbar for WebKit browsers
                }
              `}
            </style>
            <div
              className="py-6 relative mt-28 sm:mt-0 flex w-full mx-6 sm:w-[70%] md:w-[50%] lg:w-[40%] flex-col items-center justify-start isolate gap-2 rounded-[20px] bg-white shadow-lg h-[100vh] sm:h-full overflow-y-scroll no-scrollbar"
              onClick={handleModalContentClick}
            >
              <div className="absolute top-2 right-2 ">
                <div className="cursor-pointer" onClick={modalClickHandler}>
                  <RxCross2 className="bg-color1 rounded-full text-4xl p-1 m-2 sm:m-4 hover:scale-125 duration-300 hover:shadow-md hover:rotate-12" />
                </div>
              </div>

              <div className="mb-4 font-bold text-xl uppercase flex w-full justify-center">
                {heading}
              </div>
              <h1 className="font-bold text-2xl mt-8 sm:mt-12">
                Welcome to ApplyJobs.Ai
              </h1>
              <div className="mb-4 px-8 max-w-[470px] text-center">
                {description}
              </div>
              <div className="mb-4 px-8 max-w-full text-center">{children}</div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};
