import {Text} from "../atoms/Text"
import {HeroTexts, ServiceTexts} from "../particles/DataLists"
import {Card} from "../molecules/Card"
// import * as React from "react"
import {useCallback, useState} from "react"
// @ts-ignore
import GroupOfPlus from "../../assets/plusGroup.png"
// @ts-ignore
import Icon1 from "../../assets/icon14.png"
// @ts-ignore
import Icon2 from '../../assets/icon12.png'
// @ts-ignore
import Icon3 from "../../assets/icon13.png"
// @ts-ignore
import Icon4 from "../../assets/icon1.png"
// @ts-ignore
import {Fade} from "react-awesome-reveal"
import {ModalPopup} from "../molecules/ModalPopup.tsx";
import {SigninForm} from "../molecules/SigninForm.tsx";
import {RegisterForm} from "../molecules/RegisterForm.tsx";
import {Button} from "../atoms/Button.tsx";

const Services = () => {
    const [activeModal, setActiveModal] = useState<string>('')
    const renderServiceIcon = useCallback((element: number) => {
        switch (element) {
            case 0:
                return Icon1;
            case 1:
                return Icon2;
            case 2:
                return Icon3;
            case 3:
                return Icon4;
            default:
                return "";
        }
    }, []);
    // @ts-ignore
    return (
        <section
        className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6">
            {/*<Image image={GroupOfPlus} alt="Vector" className="absolute top-0 right-4 lg:h-36 h-24"/>*/}
        <main className="w-full pt-32 flex flex-col gap-3 items-center justify-center">
            {/*text-color3/80*/}
            {/*<Text as="p" className="font-light text-base tracking-widest text-white">*/}
            {/*    <Fade>{ServiceTexts.firstText}</Fade>*/}
            {/*</Text>*/}
            {/*text-color3*/}
            <Text as="h2" className="md:text-4xl text-2xl font-medium capitalize text-white">
                <Fade>{ServiceTexts.secondText}</Fade>
            </Text>

        <div className="w-full h-auto grid  lg:grid-cols-4 md:grid-cols-2 lg:gap-7 md:gap-10 gap-7 my-12 z-20 px-8 md:px-0">
      {ServiceTexts.cards.map((card, index) => (
        <Card
          key={index}
          cardClass="w-full bg-white flex flex-col items-center justify-center py-6 cursor-pointer transition duration-300 hover:shadow-xl px-5 rounded-xl cardPseudo after:bg-color1 hover:border-blue-500 hover:border-opacity-50"
          imageWrapperClass="w-28 h-28 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl"
          cover="object-cover"
          imageAlt={card.firstText}
          imageSrc={renderServiceIcon(index)}
          textWrapperClass="w-full flex flex-col items-center gap-2"
        >
          <Text as="h4" className="text-base rounded font-medium text-color3">
            {card.firstText}
          </Text>
          <Text as="p" className="text-sm font-light text-center text-color3">
            {card.secondText}
          </Text>
        </Card>
      ))}
    </div>
            <Button type="button"
                    className="outline-none border-none lg:px-9 px-5 py-3 bg-color2 text-white font-light rounded-lg"
                    onClick={() => setActiveModal('Signup')}
                    style={{
                        paddingTop: '1rem', paddingBottom: '1rem', fontSize: '1rem', fontWeight: "bold",
                        letterSpacing: '2px', marginBottom: "20px", marginTop: "-10px"
                    }}>
                {HeroTexts.firstButton}
            </Button>
            {activeModal != '' && <ModalPopup onClose={() => setActiveModal('')}
	                                          children={activeModal == 'login' ?
                                                  <SigninForm onClose={() => setActiveModal('')}/> :
                                                  <RegisterForm onClose={() => setActiveModal('')}/>}
	                                          heading={activeModal == 'login' ? "SignIn" : "Signup"}
	                                          description={activeModal == 'login' ? "Enter verification ID sent from MAilChamp.Ai" : "Enter your email to register."}/>}
        </main>

        </section>)
}

export default Services