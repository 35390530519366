import React from 'react';
import './StarRating.css'; // Make sure to create this CSS file

const StarRating = ({rating}) => {
	const totalStars = 5;
	let stars = [];
	
	// Create full, half, and empty stars based on the rating
	for (let i = 1; i <= totalStars; i++) {
		if (i <= Math.floor(rating)) {
			stars.push(<span key={i} className="star full">★</span>);
		} else if (i === Math.floor(rating) + 1 && rating % 1 !== 0) {
			stars.push(<span key={i} className="star half">★</span>);
		} else {
			stars.push(<span key={i} className="star empty">★</span>);
		}
	}
	
	return (<>
			<div className="star-rating">
				{stars}
			</div>
		</>
	);
};

export default StarRating;
