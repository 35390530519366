import React, { useState } from "react";
import specialDiscountImage from "../../assets/specialdiscount.png";
import PaymentModal from "Components/molecules/PaymentModal";

const PricingComponent = () => {
  const [isAnnually, setIsAnnually] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // Pricing data array
  const pricingData = [
    {
      key: "basic",
      name: "Basic",
      storage: "500 GB",
      users: "2 Users Allowed",
      sendLimit: "Send up to 3 GB",
      monthlyPrice: 19.99,
      annualPrice: 199.99,
    },
    {
      key: "professional",
      name: "Professional",
      storage: "1 TB",
      users: "5 Users Allowed",
      sendLimit: "Send up to 10 GB",
      monthlyPrice: 24.99,
      annualPrice: 249.99,
    },
    {
      key: "master",
      name: "Master",
      storage: "2 TB",
      users: "10 Users Allowed",
      sendLimit: "Send up to 20 GB",
      monthlyPrice: 39.99,
      annualPrice: 399.99,
    },
  ];

  const handleBuyNow = (packageKey) => {
    setSelectedPackage(pricingData.find((pkg) => pkg.key === packageKey));
  };

  return (
    <div className=" font-work-sans  bg-no-repeat bg-left-bottom sm:bg-svg-bottom relative py-0">
      <h6 className="text-center font-bold text-5xl tracking-wide relative text-black py-14 ">
        Our Pricing
      </h6>
      <div
        style={{
          marginTop: "-40px",
          display: "flex",
          justifyContent: "center", // Centers the image horizontally
          alignItems: "center", // Centers the image vertically
          // height: '100vh'           // Takes full viewport height
        }}
      >
        <img
          src={specialDiscountImage}
          alt="Special Discount"
          style={{
            // width: '90%',
            maxHeight: "200px",
            maxWidth: "200px",
          }}
        />
      </div>
      <div className="flex flex-row justify-center my-5 text-sm tracking-tight font-medium text-gray-300">
        <button
          className={`mx-3 ${
            !isAnnually ? "text-blue-200 font-bold" : "text-gray-400"
          }`}
          onClick={() => setIsAnnually(false)}
        >
          Monthly
        </button>
        <button
          className={`mx-3 ${
            isAnnually ? "text-blue-200 font-bold" : "text-gray-400"
          }`}
          onClick={() => setIsAnnually(true)}
        >
          Annually
        </button>
      </div>

      <div className="px-12">
        <div className="flex flex-col md:flex-row md:transform md:scale-75 lg:scale-100 justify-center">
          {/* Basic card */}
          <div className="border w-full rounded-lg md:rounded-r-none text-center p-5 mx-auto md:mx-0 my-2 md:my-6 bg-gray-100 font-medium z-10 shadow-lg py-12">
            <div>Basic</div>
            <div className="font-bold text-6xl">
              $
              {isAnnually
                ? pricingData[0].annualPrice
                : pricingData[0].monthlyPrice}
            </div>
            <hr />
            <div className="text-sm my-3">{pricingData[0].storage} Storage</div>
            <hr />
            <div className="text-sm my-3">{pricingData[0].users}</div>
            <hr />
            <div className="text-sm my-3">{pricingData[0].sendLimit}</div>
            <hr />
            <button onClick={() => handleBuyNow(pricingData[0].key)}>
              <div className="bg-gradient-to-r from-blue-600 to-blue-400 border border-blue-600 hover:bg-transparent text-white hover:text-blue-600 font-bold uppercase text-xs mt-5 py-2 px-4 rounded cursor-pointer">
                Buy Now
              </div>
            </button>
          </div>

          {/* Professional card */}
          <div className="border-transparent w-full rounded-lg text-center p-5 mx-auto md:mx-0 my-2 bg-gradient text-white font-medium z-10 shadow-lg py-12">
            <div className="py-4">Professional</div>
            <div className="font-bold text-6xl">
              $
              {isAnnually
                ? pricingData[1].annualPrice
                : pricingData[1].monthlyPrice}
            </div>
            <hr />
            <div className="text-sm my-3">{pricingData[1].storage} Storage</div>
            <hr />
            <div className="text-sm my-3">{pricingData[1].users}</div>
            <hr />
            <div className="text-sm my-3">{pricingData[1].sendLimit}</div>
            <hr />
            <button onClick={() => handleBuyNow(pricingData[1].key)}>
              <div className="bg-white border border-white hover:bg-transparent text-blue-600 hover:text-white font-bold uppercase text-xs mt-5 py-2 px-4 rounded cursor-pointer">
                Buy Now
              </div>
            </button>
          </div>

          {/* Master card */}
          <div className="border w-full rounded-lg  md:rounded-l-none text-center p-5 mx-auto md:mx-0 my-2 md:my-6 bg-gray-100 font-medium z-10 shadow-lg py-12 ">
            <div>Master</div>
            <div className="font-bold text-6xl">
              $
              {isAnnually
                ? pricingData[2].annualPrice
                : pricingData[2].monthlyPrice}
            </div>
            <hr />
            <div className="text-sm my-3">{pricingData[2].storage} Storage</div>
            <hr />
            <div className="text-sm my-3">{pricingData[2].users}</div>
            <hr />
            <div className="text-sm my-3">{pricingData[2].sendLimit}</div>
            <hr />
            <button onClick={() => handleBuyNow(pricingData[2].key)}>
              <div className="bg-gradient-to-r from-blue-600 to-blue-400 border border-blue-600 hover:bg-transparent text-white hover:text-blue-600 font-bold uppercase text-xs mt-5 py-2 px-4 rounded cursor-pointer">
                Buy Now
              </div>
            </button>
          </div>
        </div>
      </div>
      {selectedPackage && (
        <PaymentModal
          packageInfo={selectedPackage}
          onClose={() => setSelectedPackage(null)}
        />
      )}
    </div>
  );
};

export default PricingComponent;
