import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../atoms/Button";
import { NavButtons } from "../particles/DataLists";
import { List } from "../atoms/List";
import { Slide } from "react-awesome-reveal";

import { TiThMenu } from "react-icons/ti";
import { LoginSignupModal } from "../molecules/LoginSignupModal";
import { RxCross2 } from "react-icons/rx";

const NavBar = ({ signedin, isLocalRun }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [clicked, setClicked] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    setClicked(true);
    if (!open) {
      setTimeout(() => {
        setClicked(false);
      }, 900);
    }
  };

  const handleButtonClick = (navbutton) => {
    if (navbutton.name === "Login" || navbutton.name === "Signup") {
      navigate("/login");
    } else {
      navigate(navbutton.url);
    }
    setOpen(false);
    setClicked(false);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    setClicked(false);
  };

  if (signedin) {
    return null;
  }

  const openCombinedModal = () => {
    setActiveModal("combined");
  };

  const closeCombinedModal = () => {
    setActiveModal("");
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50 overflow-x-hidden">
      <Slide direction="down">
        <nav className="bg-transparent backdrop-blur-sm border-b shadow-2xl lg:px-24 md:px-12 px-8 flex justify-between items-center h-20">
          <div className="flex items-center">
            <a
              href="/"
              className="font-bold text-gray-400 text-3xl sm:text-2xl"
            >
              Apply<span className="text-color2">Jobs</span>.Ai
            </a>
          </div>
          {!open && (
            <Button
              className={`text-white bg-red-950 lg:hidden ${
                clicked ? "pointer-events-none" : ""
              }`}
              onClick={handleToggle}
            >
              <TiThMenu
                className={`text-4xl duration-300 ${
                  clicked
                    ? ""
                    : "hover:scale-125 hover:shadow-md hover:rotate-90"
                }`}
              />
            </Button>
          )}

          {!open && (
            <button
              className=" hidden lg:block text-white "
              onClick={openCombinedModal}
            >
              Login/Register
            </button>
          )}
        </nav>
      </Slide>

      {activeModal === "combined" && (
        <LoginSignupModal onClose={closeCombinedModal} isLocalRun={isLocalRun}/>
      )}

      {open && (
        <div
          className="w-full h-screen z-50 fixed top-0 left-0 backdrop-blur-sm bg-opacity-50"
          onClick={handleCloseMenu}
        >
          <nav className="fixed top-0 right-0 h-screen w-[70%] bg-black text-white rounded-l-2xl transition translate-x-0 duration-500 ease-out">
            <Button
              onClick={handleToggle}
              className=" m-2 cursor-pointer self-end"
            >
              <RxCross2 className=" bg-color1 rounded-full text-4xl p-1 h-12 w-12 hover:scale-125 duration-300 hover:shadow-md hover:rotate-12" />
            </Button>

            <div className="p-6 flex flex-col items-center h-full ">
              <ul className="flex flex-col gap-4 text-white">
                {NavButtons.map((navbutton, index) => (
                  <List key={index}>
                    <Button
                      onClick={() => handleButtonClick(navbutton)}
                      className=" font-bold text-2xl under py-1.5 px-5 hover:text-color2 hover:border-b-2 hover:border-color2 transition"
                    >
                      {navbutton.name}
                    </Button>
                  </List>
                ))}
                <button
                  className="font-bold text-2xl under py-1.5 px-5 hover:text-color2 hover:border-b-2 hover:border-color2 transition text-white"
                  onClick={openCombinedModal}
                >
                  Login/Register
                </button>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default NavBar;
