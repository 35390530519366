import HeroSection from "../organs/HeroSection";
import Services from "../organs/Services";
import ContactUs from "../organs/Contactus";
// import * as React from "react"
import "atropos/css";
// @ts-ignore
// import Registration from "../../Components/Demo/EmailOnboarding/Registration.js"
// @ts-ignore
// import Login from "../../Components/Demo/EmailOnboarding/Login.js"
// import Atropos from 'atropos/react';
// import 'atropos/atropos.css';
// import 'swiper/swiper-bundle.min.css';
import "swiper/css";
import "./home.css";
import Review from "Components/organs/Review";
import VideoTabs from "Components/organs/VideoTabs";
import Expandable from "Components/organs/Expandable";
// import Testimonials from "Components/organs/Testimonials";
// import Testimonials from "Components/organs/Testimonials";
// import Parallax from './Parallax.js'
import PricingComponent from "Components/organs/PricingComponent";

const Home = ({ registeredUsers, jobsAvailable, jobsApplied }) => {
  return (
    <>
      <HeroSection
        registeredUsers={registeredUsers}
        jobsAvailable={jobsAvailable}
        jobsApplied={jobsApplied}
      />
      {/* <ScrollAnimation/> */}
      {/* <div>
                 <Testimonials/>
                </div> */}
      <div
        style={{
          background: `
                        radial-gradient(circle at center top, black 25%, transparent 100%),
                        radial-gradient(circle at left top, blue 40%, transparent 50%),
                        radial-gradient(circle at right top, purple 40%, transparent 50%)
                      `,
        }}
      >
        <Services />
      </div>
      <PricingComponent />
      <Expandable />
      <VideoTabs />
      <Review />
      <ContactUs />
    </>
  );
};

export default Home;
