import {Image} from "../atoms/Image"
import {Text} from "../atoms/Text"
import {ContactUsTexts} from "../particles/DataLists"
// @ts-ignore
import groupOfPlus from "../../assets/plusGroup2.png"
import {ContactForm} from "../molecules/ContactForm"
// @ts-ignore
import React from "react";


const ContactUs = () => {
    return (
        <section className="w-full my-32 flex justify-between relative items-start h-[400px] mt-0 mb-0">
            <div className="bg-color3/10 w-[30%] h-[70%] self-end rounded-r-xl"></div>
            <div className="bg-color3/10 w-[30%] h-[70%] rounded-l-xl"></div>
            <div className="w-full bg-color3/5 h-[100%] absolute flex flex-col items-center
            justify-center md:gap-16 gap-10 px-5">
                <Text as="h1" className="lg:text-3xl md:text-2xl text-xl text-center text-color3/70 lg:w-3/5 w-full">
                    {ContactUsTexts.firstText}
                </Text>
                <ContactForm />
            </div>
            <Image image={groupOfPlus} alt="Plus" className="absolute -bottom-16 right-2 h-32" />
        </section>
    )
}

export default ContactUs